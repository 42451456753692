<template>
  <div class="drawer__container">
    <div class="loader" v-if="isLoadingDetails">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
    <div class="px-2 pl-sm-6 pl-md-8 py-2" v-else>
      <div>
        <p class="drawer_title">Form Entry</p>
        <p class="drawer_info mb-0">Title</p>
        <p class="form__title">
          {{ entryDetails ? entryDetails.data.form_title : "" }}
        </p>
        <div class="section__div mb-3" v-if="status">
          <p class="headerText mb-0">Status</p>
          <v-chip
            small
            :color="chipColor"
            class="
              d-inline-flex
              justify-center
              align-center
              ml-n2
              px-3
              font-weight-bold
              text-body-2 text-capitalize
              mt-0
            "
          >
            {{ status }}
            <img :src="chipIcon" alt="" class="ml-4" />
          </v-chip>
        </div>
        <div class="section__div mb-3">
          <p class="headerText mb-0">Entry ID</p>
          <p class="valueText mb-0">
            {{ entryDetails ? entryDetails.id : "" }}
          </p>
        </div>
        <div>
          <div class="section__div">
            <p class="headerText mb-0">Entry</p>
            <div
              class="fixed-container"
              :style="
                approvals && approvals.length > 0
                  ? containerHeight
                  : adjustedHeight
              "
            >
              <v-row
                class="entryItemContainer"
                v-for="(item, i) in filteredFormEntries"
                :key="i"
              >
                <v-col cols="4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        class="entry__title text-truncate"
                        style="max-width: 400px"
                      >
                        {{ item.name }}
                      </p>
                    </template>
                    <span>{{ item.name }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="8">
                  <a
                    class="linkText"
                    v-if="
                      typeof item.value === 'object' &&
                      item.value.hasOwnProperty('file')
                    "
                    :href="item.value.file"
                    target="_blank"
                    :download="item.value.name"
                  >
                    {{ item.value.name }}
                  </a>

                  <p class="entry__value" v-else>
                    {{ item.value }}
                  </p>
                </v-col>
              </v-row>
              <template v-if="entryDetails && entryDetails.data.attachment">
                <div>
                  <v-row
                    v-for="(item, i) in entryDetails.data.attachment"
                    :key="i"
                  >
                    <v-col cols="4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <p
                            v-bind="attrs"
                            v-on="on"
                            class="entry__title mb-1 text-truncate"
                          >
                            {{ item.attachment_name }}
                          </p>
                        </template>
                        <span>{{ item.attachment_name }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="8">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-bind="attrs"
                            v-on="on"
                            class="linkText text-truncate"
                            :href="item.url"
                            target="_blank"
                          >
                            {{ item.url }}
                          </a>
                        </template>
                        <span>{{ item.url }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </template>
              <!-- <pre>{{ filteredFormEntriesForArraysOnly }}</pre> -->
              <!-- for payment data Only-->
              <div
                v-for="(item, i) in filteredFormEntriesForArraysOnly"
                :key="i"
                class="mb-3"
              >
                <v-row class="paymentData rounded d-flex flex-column pa-4 mt-2">
                  <v-col class="pa-0 mb-2">
                    <p class="entry__title px-3">{{ item.name }}</p>
                  </v-col>
                  <v-col
                    class="ma-2 pa-2 cardLoop rounded-4"
                    v-for="(data, j) in item.value"
                    :key="j"
                  >
                    <template v-for="(value, key) in data">
                      <v-row
                        class="mx-3 d-flex align-center entryItemContainer"
                        v-if="key != 'id'"
                        :key="key"
                      >
                        <v-col cols="4" class="pa-0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                v-bind="attrs"
                                v-on="on"
                                class="entry__title text-truncate"
                              >
                                {{ key }}
                              </p>
                            </template>
                            <span>{{ key }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="8" class="pa-0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                v-bind="attrs"
                                v-on="on"
                                class="entry__value text-truncate"
                                style="max-width: 400px; color: #465263"
                              >
                                {{ value }}
                              </p>
                            </template>
                            <span>{{ value }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <div class="approval-container">
            <div class="comment">
              <template>
                <div
                  class="view-comments"
                  v-if="approvals && approvals.length > 0"
                >
                  <img
                    :src="require('@/assets/approval-approved.svg')"
                    alt=""
                  />
                  <button @click="showApprovals = !showApprovals">
                    {{ showApprovals ? "hide" : "view" }} approvals
                  </button>
                </div>
                <div class="titleh" :class="{ show: showApprovals }">
                  Below is a timeline of approvals for this form entry
                </div>

                <div
                  class="timeline"
                  :class="{ show: showApprovals }"
                  v-if="approvals && approvals.length > 0"
                >
                  <span v-for="(approval, index) in approvals" :key="index">
                    <div class="timeline__data">
                      <div class="head">
                        <span class="approver">{{
                          approval.approver.username
                        }}</span>
                        <span class="status"
                          >{{ approval.is_approved ? "Approved" : "Denied" }}
                          <img
                            :src="
                              require(`@/assets/approval-${
                                approval.is_approved ? 'approved' : 'denied'
                              }.svg`)
                            "
                          />
                        </span>
                      </div>

                      <div class="quote">
                        {{ approval.comment }}
                      </div>

                      <div class="date">
                        {{ approval.created_at | date("full") }}
                      </div>
                    </div>
                    <div
                      v-if="index !== approvals.length - 1"
                      class="timeline__divider"
                    ></div>
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          class="d-block d-md-flex justify-md-space-between align-center my-6"
        >
          <v-btn
            large
            class="pa-2 text-capitalize"
            elevation="0"
            color="f4f5f6"
          >
            <v-icon class="px-0">mdi-chevron-right</v-icon>
            Push to Workflow
          </v-btn>
          <v-btn
            large
            class="py-2 px-3 text-capitalize"
            elevation="3"
            color="primary"
          >
            <v-icon color="#fff" small class="px-2">mdi-reload</v-icon>
            Re-submit
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "singleEntriesDetails",
  props: {
    entryId: null,
  },
  data() {
    return {
      isLoadingDetails: false,
      showApprovals: false,
      status: null,
      containerHeight: {
        height: "25vh",
      },
      adjustedHeight: {
        height: "200px",
      },
    };
  },

  computed: {
    ...mapGetters({
      form: "formBuilder/getSingleForm",
      organId: "auth/organizationId",
      entryDetails: "formBuilder/entriesDetails",
      approvals: "transactions/getFormEntryApprovals",
    }),

    chipColor() {
      switch (this.status) {
        case "open":
          return "#fdf1ce";
        case "closed":
          return "#dbe4f0";
        case "rejected":
          return "#ffcccc";
        case "approved":
          return "#d4f6ef";
        default:
          return "#fdf1ce";
      }
    },
    chipIcon() {
      switch (this.status) {
        case "open":
          return require("@/assets/Check_ring_open.svg");
        case "closed":
          return require("@/assets/Check_ring_closed.svg");
        case "rejected":
          return require("@/assets/Check_ring_rejected.svg");
        case "approved":
          return require("@/assets/Check_ring_open.svg");
        default:
          return "";
      }
    },

    // turn the form_entry to array
    formEntryArrayComputed() {
      let entryObject = this.entryDetails?.data?.form_entry;

      if (!entryObject) {
        return []; // Return an empty array if entryObject is null or undefined
      }

      let entryArray = [];

      for (const [key, value] of Object.entries(entryObject)) {
        // Replace underscores with spaces in the key
        const formattedKey = key.replace(/_/g, " ");

        entryArray.push({
          name: formattedKey,
          value: value,
        });
      }

      return entryArray;
    },

    filteredFormEntries() {
      // Filter the formEntryArrayComputed array to exclude items with arrays
      return this.formEntryArrayComputed.filter(
        (item) => !Array.isArray(item.value)
      );
    },
    filteredFormEntriesForArraysOnly() {
      // Filter the formEntryArrayComputed array to show items with arrays only
      return this.formEntryArrayComputed.filter((item) =>
        Array.isArray(item.value)
      );
    },
  },

  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async getEntry(id) {
      try {
        this.isLoadingDetails = true;
        const { data } = await this.$store.dispatch(
          "formBuilder/getSingleFormEntry",
          id
        );
        if (data.data.status) {
          this.status = data.data.status;
        }
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "error fetching entry details",
          timeout: 3000,
        });
      } finally {
        this.isLoadingDetails = false;
      }
    },
    async getEntryApprovals(id) {
      try {
        this.isLoadingDetails = true;
        await this.$store.dispatch("transactions/getTransactionApprovals", id);

        // console.log(data);
      } catch (error) {
        console.log(error);
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg || "error fetching approvals",
          timeout: 3000,
        });
      } finally {
        this.isLoadingDetails = false;
      }
    },
  },

  watch: {
    entryId: {
      handler: function (val) {
        if (val) {
          this.getEntry(val);
          this.getEntryApprovals(val);
        }

        // console.log(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px 8px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }
}

p {
  margin-bottom: 0px;
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(25, 40, 61, 0.8);
}

.drawer_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #19283d;
}

.form__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #19283d;
}

.entry__value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27.49px;
  color: rgba(25, 40, 61, 0.8);
  margin-bottom: 0px;
}

.entry__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 27.49px;
  color: rgba(25, 40, 61, 0.8);
  margin-bottom: 0px;
}

.drawer_info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: rgba(25, 40, 61, 0.8);
}

.txt_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #19283d;
  cursor: pointer;
}

.entryItemContainer {
  border-bottom: 1px dashed rgba(25, 40, 61, 0.2);
}

.hypn-input >>> ::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: #8f96a1;
}

.input__icon {
  line-height: 24px;
  padding-right: 1px;
  padding-left: 2px;
}

.v-text-field ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

.v-select ::v-deep input {
  font-size: 0.8em;
  font-weight: 600;
  text-transform: capitalize;
}

#clickArea {
  width: 100%;
  height: 126px;
  background-color: #ffffff;
  border: 1px dashed rgba(127, 145, 155, 0.551929);
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.docTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.filename {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  color: rgba(25, 40, 61, 0.8);
}

.loader {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
  border-radius: 6px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
}

.section_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}

.text-truncate-left {
  width: 45px;
  overflow: hidden;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.headerText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #8f96a1;
  display: block;
}

.valueText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color: #19283d;
  display: block;
}

.linkText {
  color: rgba(91, 103, 186, 1);
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27.49px;
  text-decoration: underline;
  margin-bottom: 0px;
}

.paymentData {
  background-color: #f8f9fac0;
}

.cardLoop {
  background-color: #f8f9fa;
}

p {
  margin-bottom: 0px;
}

.fixed-container {
  // height: 25vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.fixed-container::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.fixed-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.fixed-container::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 10px;
}

.approval-container {
  height: 25vh;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }
}

.comment {
  flex: 1;

  //1300px and above
  @media screen and (min-width: 1300px) {
    overflow: auto;
  }

  margin: 5px 0;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }

  .view-comments {
    text-align: left;
    margin: 20px 0;
    color: #5b67ba;
    display: flex;
    align-items: center;
    gap: 7px;

    img {
      width: 20px;
      height: 20px;
    }

    button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;
    }
  }

  .titleh {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #525e6e;
    display: none;

    &.show {
      display: block;
    }
  }

  .timeline {
    flex: 1;
    overflow-y: auto;

    height: 0px;
    transition: all 0.3s ease-in-out;

    &.show {
      height: auto;
      padding: 30px 0px;
    }

    &__divider {
      margin: auto;
      height: 35px;
      width: 1px;
      border-right: 2px dashed #8f96a1;
    }

    &__data {
      box-sizing: border-box;
      padding: 15px;

      background: #ffffff;
      /* line */

      border: 1px solid #d9dee1;
      border-radius: 4px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;

        .approver {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 28px;
          color: #19283d;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status {
          font-family: "Inter";
          font-style: italic;
          font-weight: 500;
          font-size: 13px;
          line-height: 28px;
          color: #8f96a1;
          display: inline-flex;
          align-items: center;
          gap: 5px;
        }
      }

      .quote {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15;
        line-height: 24px;
        /* or 200% */

        /* menu txt */

        color: #525e6e;
        margin: 10px 0px;
      }

      .date {
        text-align: right;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: rgba(25, 40, 61, 0.8);
      }
    }
  }
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
