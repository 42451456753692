import api from "./Api";

export default {
  // create a new quote
  createNewQuote(organization_hypnID, payload) {
    return api().post("/quotes/" + organization_hypnID, payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // update a single quote
  updateQuote(organization_hypnID, quoteHypnId, payload) {
    return api().put(
      "/quotes/" + organization_hypnID + "/" + quoteHypnId,
      payload,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  // get a single quote
  getQuote(organization_hypnID, quoteHypnId) {
    return api().get("/quotes/" + organization_hypnID + "/" + quoteHypnId, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  // get all quotes
  getAllQuotes(organization_hypnID) {
    return api().get(
      // "/quotes/" + organization_hypnID + "?_start=" + start + "&_limit=" + limit,
      "/quotes/" + organization_hypnID,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  // convert a quote to an invoice
  convertQuoteToInvoice(organization_hypnID, quoteHypnId) {
    return api().post(
      "/quotes/" + organization_hypnID + "/" + quoteHypnId + "/invoice",
      null,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  // tigger download of a quote pdf
  downloadQuotePDF(organization_hypnID, payload) {
    return api().post("/quotes/" + organization_hypnID + "/send", payload, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  //generate delivery note from quote or purchase order
  generateDeliveryNote(organization_hypnID, payload) {
    return api().post(
      "/documents/" + organization_hypnID + "/waybill",
      payload,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },
};
